import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { TOKEN_KEY } from './constants';

const PrivateRoutes = () => {
  let auth = sessionStorage.getItem(TOKEN_KEY);
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
