import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { TOKEN_KEY } from './constants';

const AUTH = gql`
  mutation auth($input: AuthParams!) {
    auth(input: $input) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`;

function Login() {
  const navigate = useNavigate();
  const [auth] = useMutation(AUTH);

  const getBEAccessToken = async ({ code }) => {
    const result = await auth({ variables: { input: { issueFor: 'metrics', code } } });
    const accessToken = result.data.auth.accessToken;
    sessionStorage.setItem(TOKEN_KEY, accessToken);
    navigate('/');
  };
  const errorMessage = error => {
    console.log(error);
    navigate('/error');
  };
  const login = useGoogleLogin({
    onSuccess: getBEAccessToken,
    onError: errorMessage,
    flow: 'auth-code',
  });

  return (
    <div>
      <Box textAlign="center">
        <h2>Aware Health Metrics Dashboard</h2>
        <Button variant="outlined" onClick={login}>
          Log in
        </Button>
      </Box>
    </div>
  );
}

export default Login;
